import * as React from "react";
import * as ReactDOM from "react-dom";
import Ajax from "../Ajax";
import Password from './items/Password';
import Email from "./items/Email";
import Username from "./items/Username";

interface IFormProps {
  domain: string,
  language: string,
  texts: string,
  email: string,
}
interface MyStates {
    message: string
    message_title: string
    message_type: string
    ajaxloaded: boolean,
    clicked: boolean,
}
export default class RegForm extends React.Component<IFormProps, MyStates>{
  private text = [];
  private interests:object;
  constructor(props:IFormProps) {
    super(props);

    this.text = JSON.parse(this.props.texts);

    this.state = {message: '', message_title: '', message_type: '', ajaxloaded: false, clicked: false}
    this.handleSubmit = this.handleSubmit.bind(this)
    this.loadInterests()
  }

  private loadInterests() {
    var ajax = new Ajax( this.props.domain + '/api/getinterests' );
    ajax.doPost({ language_id: this.props.language }).then((response) => {
      this.interests = response
      this.setState( { ajaxloaded: true } )
    })
  }

  private handleSubmit(event) {
    event.preventDefault();
    var ajax = new Ajax( this.props.domain + '/api/registrationform' );
    ajax.doPost('regform', true).then((response) => {
      this.setState({message_type: response.status, message: response.msg, message_title: response.title})
    })
  }

  public render() {
    if(this.state.ajaxloaded){
      return (
        <>
          <form onSubmit={this.handleSubmit} id="regform" key={"registrationform" + Math.random()}>
            <div className="container">
              <div className="row form">
                <div className="col-12">
                  <h2>{this.text['data_3']}</h2>
                </div>
              </div>
            </div>
            <div className="container container_form_first">
              <div className="row form">
                <div className="col-12 col-md-2 col-lg-2">
                  <select className="p_form_control" name="name_prefix">
                    <option value="">Előtag</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Ms.">Ms.</option>
                  </select>
                </div>
                <div className="col-12 col-md-5 col-lg-5">
                  <input type="text" className="p_form_control" name="last_name" placeholder="Vezetéknév" required />
                </div>
                <div className="col-12 col-md-5 col-lg-5">
                  <input type="text" className="p_form_control" name="first_name" placeholder="Keresztnév" required />
                </div>
              </div>
              <div className="row form">
                <Email domain={this.props.domain} language={this.props.language} email={this.props.email}/>
                <Username domain={this.props.domain} language={this.props.language}/>
              </div>
              <Password domain={this.props.domain} language={this.props.language}/>
              <div className="row form">
                <div className="col-12 col-md-6">
                  <select className="p_form_control" name="gender">
                    <option value="">Neme</option>
                    <option value="male">Férfi</option>
                    <option value="female">Nő</option>
                  </select>
                </div>
                <div className="col-12 col-md-6">
                  <input type="text" className="p_form_control" name="birthdate" placeholder="Születési dátum" />
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row form">
                <div className="col-12">
                  <h2>{this.text['data_4']}</h2>
                </div>
              </div>
            </div>

            <div className="container white_container">
              <div className="white">
                <div className="row form interests">
                  {Object.keys(this.interests).map((i, rt) => (
                  <div className="col-12 col-md-6 checkbox" key={'interests_'+i}>
                    <input type="checkbox" id={'interests_'+i} name="interests" value={this.interests[rt].id} />
                    <label htmlFor={'interests_'+i}> {this.interests[rt].title} </label>
                  </div>
                  ))}
                </div>
                <div className="row form">
                  <div className="col-12 aszf checkbox">
                    <input type="checkbox" id="aszf" name="aszf" value="accepted" required />
                    <label htmlFor="aszf"> <a href={this.props.domain + '/uploads/PUSKAS_ARENA_adatkezelesi.tajekoztato.pdf'} target="_BLANK">{this.text[40]}</a> </label>
                  </div>
                </div>
                <div className="row form">
                  <div className="col-12 btn">
                    <input type="password" className="form-control alt" name="password__again" /> 
                    <button className="btn_red" name="contact">Regisztrálok a Puskás Aréna Klubba</button>
                  </div>
                </div>
                {this.state.message_type != ''?
                  <div className={ "message_" + this.state.message_type }>
                    <div className="title">{this.state.message_title}</div>
                    {this.state.message}
                  </div>
                  :<></>}
              </div>
            </div>


          </form>
        </>
      );
    }else{
      return (<></>)
    }

  }
}

export class InitRegForm {
  constructor() {
      var div: HTMLDivElement = document.getElementById('registration_form') as HTMLDivElement;
      if (div !== null) {
          var data:any = div.dataset;
          ReactDOM.render(<RegForm domain={data.domain} language={data.language} texts={data.texts} email={data.email} />, div);
      }
  }
}
