declare var $:any;

export default class Ajax{
  private message: any;
  private status: any;
  private url: string;
  private contenttype: string;

  constructor(url, contenttype = "application/json; charset=UTF-8") {
    this.url = url;
    this.contenttype = contenttype;
  }
  async doPost(data, form = false){
    var sendData = '';
    if(form){
      sendData = this.serializeToJson(data);
    }else{
      sendData = JSON.stringify(data);
    }

    if(this.contenttype == "application/json; charset=UTF-8"){
      return await fetch(this.url, {
        method: 'POST',
        body: sendData,
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }).then((response) => response.json());
    }else{
      return await fetch(this.url, {
        method: 'POST',
        body: sendData,
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }).then((response) => response.text());
    }
    
  }
  async doGet(){
    return await fetch(this.url).then((response) => response.json());
  }
  async doUpload(input){
    const { target } = input
    if(target.value.length > 0){
      const formData = new FormData();
      $.each(target.files, function(key, value){
        formData.append('file_'+key, value);
      });
      return await fetch(this.url, {
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': (document.querySelector('meta[name="csrf-token"]') as any).getAttribute('content')
        },
        body: formData
      }).then((response) => response.json());

    } else {
      target.reset();
    }
  }

  public getStatus() {
    return {
      status: this.status,
      message: this.message,
    };
  }

  private serializeToJson(formName) {
    $.fn.serializeObject = function() {
      var o = Object.create(null),
          elementMapper = function(element) {
              element.name = $.camelCase(element.name);
              return element;
          },
          appendToResult = function(i, element) {
              var node = o[element.name];
  
              if ('undefined' != typeof node && node !== null) {
                  o[element.name] = node.push ? node.push(element.value) : [node, element.value];
              } else {
                  o[element.name] = element.value;
              }
          };
  
      $.each($.map(this.serializeArray(), elementMapper), appendToResult);
      return o;
  };
    //var form = {form: $('#'+formName).serializeObjectWithFile()};
    var form = $('#'+formName).serializeObject();
    //console.log(form);
    return JSON.stringify({'form':form});
  }
}