import * as React from "react";
import * as ReactDOM from "react-dom";
import Ajax from "../Ajax";

interface IFormProps {
  domain: string,
  language: string,
  texts: string,
  rts: string,
}
interface MyStates {
    message: string
    message_title: string
    message_type: string
}
export default class OfferRequestForm extends React.Component<IFormProps, MyStates>{
  private text = '';
  private rts = {data:{}};

  constructor(props:IFormProps) {
    super(props);

    this.text = JSON.parse(this.props.texts);
    this.rts = JSON.parse(this.props.rts);

    this.state = {message: '', message_title: '', message_type: ''}
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  private handleSubmit(event) {
    event.preventDefault();
    var ajax = new Ajax( this.props.domain + '/api/offerrequestform' );
    ajax.doPost('orform', true).then((response) => {
      this.setState({message_type: response.status, message: response.msg, message_title: response.title})
    })
  }

  public render() {
    return (
      <>
        <form onSubmit={this.handleSubmit} id="orform" key={"contactform" + Math.random()}>
          <div className="row">
            <div className="col-lg-6">
              <input name="name" className="p_form_control" type="text" placeholder={this.text[18]} required />
              <input name="company" className="p_form_control" type="text" placeholder={this.text[19]} required />
              <input name="phone" className="p_form_control" type="tel" placeholder={this.text[20]} required />
              <input name="email" className="p_form_control" type="email" placeholder={this.text[21]} required />
              <select name="place" className="p_form_control" required>
                  <option value="">{this.text[48]}</option>
                  {Object.keys(this.rts).map((i, rt) => (
                    <option value={this.rts[rt].title}>{this.rts[rt].title}</option>
                  ))}
              </select>
            </div>
            <div className="col-lg-6">
              <textarea name="message" className="p_form_control" placeholder={this.text[22]}></textarea>
            </div>
            <div className="col-12 aszf checkbox">
              <input type="checkbox" id="aszf" name="aszf" value="accepted" required />
              <label htmlFor="aszf"> <a href={this.props.domain + '/uploads/PUSKAS_ARENA_adatkezelesi.tajekoztato.pdf'} target="_BLANK">{this.text[48]}</a>
              </label>
            </div>
          </div>
          {this.state.message_type != ''?
            <div className={ "message_" + this.state.message_type }>
              <div className="title">{this.state.message_title}</div>
              {this.state.message}
            </div>
            :<></>}
          <div className="row">
            <div className="col-12 btn">
              <button type="submit" name="place_request" className="btn_green">{this.text[23]}</button>
              <button className="btn_red_small" data-dismiss="modal" aria-label="Close">{this.text[24]}</button>
            </div>
          </div>
        </form>
      </>
    );
    
  }
}

export class InitOfferRequestForm {
  constructor() {
    var div: HTMLDivElement = document.getElementById('orf') as HTMLDivElement;
    if (div !== null) {
      var data:any = div.dataset;
      ReactDOM.render(<OfferRequestForm domain={data.domain} language={data.language} texts={data.texts} rts={data.rts} />, div);
    }
  }
}