import * as React from "react";
import * as ReactDOM from "react-dom";
import Ajax from "../../Ajax";

interface IFormProps {
  domain: string,
  language: string,
}
interface MyStates {
  passwordCheck: number,
  clicked: boolean,
}
export default class Password extends React.Component<IFormProps, MyStates>{
  private passwordStatus = {
    chars: 'invalid',
    letters: 'invalid',
    specchars: 'invalid',
    correct: 'invalid'
  };
  private passwordMsg = {
    chars: 'Minimum 8 maximum 16 karaktert kell megadni!',
    letters: 'Legyen benne kis- és nagybetű, valamint szám',
    specchars: 'Legalább egy speciális karaktert meg kell adni!',
    correct: 'Nem egyeznek a beírt jelszavak!'
  };
  private pw;
  private pw2;

  constructor(props:IFormProps) {
    super(props);

    this.state = {passwordCheck: 0, clicked: false}
  }


  private check_password(e){
    var ajax = new Ajax( this.props.domain + '/api/checkpassword' );
    ajax.doPost({pw: this.pw, pw2:this.pw2}).then((response) => {
      this.passwordStatus = {
        chars: response.chars,
        letters: response.letters,
        specchars: response.specchars,
        correct: response.correct
      };
      this.setState({
        passwordCheck: response.time,
      })
    })
  }
  private setPw(e, switcher){
    this.setState({clicked: true})
    if(switcher == 'pw'){
      this.pw = e.target.value
    }
    if(switcher == 'pw2'){
      this.pw2 = e.target.value
    }
  }

  public render() {
    return (
      <div className="row form">
        <div className="col-12 col-md-6">
          <input type="password" className="p_form_control" name="pw" placeholder="Jelszó" onChange={(e) => this.setPw(e, 'pw')} onBlur={ (e) => this.check_password(e) } autoComplete="new-password" required />
        </div>
        <div className="col-12 col-md-6">
          <input type="password" className="p_form_control" name="pw2" placeholder="Jelszó megerősítése" onChange={(e) => this.setPw(e, 'pw2')} onBlur={ (e) => this.check_password(e) } required />
        </div>
        <div className={"col-12" + (this.state.clicked?'':' hiddencallout') } key={"password_"+this.state.passwordCheck}>
          <div className={this.passwordStatus.chars == 'valid' && this.passwordStatus.letters == 'valid' && this.passwordStatus.specchars == 'valid' && this.passwordStatus.correct == 'valid'?'callout callout-success':'callout callout-danger'}>
            {this.passwordStatus.correct == 'valid'?<p className="hidden">{this.passwordMsg.correct}</p>:<p className="error">{this.passwordMsg.correct}</p>}
            {this.passwordStatus.chars == 'valid'?<p className="hidden">{this.passwordMsg.chars}</p>:<p className="error">{this.passwordMsg.chars}</p>}
            {this.passwordStatus.letters == 'valid'?<p className="hidden">{this.passwordMsg.letters}</p>:<p className="error">{this.passwordMsg.letters}</p>}
            {this.passwordStatus.specchars == 'valid'?<p className="hidden">{this.passwordMsg.specchars}</p>:<p className="error">{this.passwordMsg.specchars}</p>}
          </div>
        </div>
      </div>
    );
  }
}