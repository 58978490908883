import * as React from "react";
import * as ReactDOM from "react-dom";
import Ajax from "../Ajax";

interface IFormProps {
  domain: string,
  language: string,
  texts: string,
}
interface MyStates {
    message: string
    message_title: string
    message_type: string
}
export default class ContactForm extends React.Component<IFormProps, MyStates>{
  private text = [];
  constructor(props:IFormProps) {
    super(props);

    this.text = JSON.parse(this.props.texts);
    console.log(this.text);

    this.state = {message: '', message_title: '', message_type: ''}
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  private handleSubmit(event) {
    event.preventDefault();
    var ajax = new Ajax( this.props.domain + '/api/contactform' );
    ajax.doPost('contactform', true).then((response) => {
      this.setState({message_type: response.status, message: response.msg, message_title: response.title})
    })
  }

  public render() {
    return (
      <>
        <form onSubmit={this.handleSubmit} id="contactform" key={"contactform" + Math.random()}>
          <div className="row">
            <div className="col-12 col-lg-6">
                <input name="name" className="p_form_control" type="text" placeholder={this.text[18]} required />
            </div>
            <div className="col-12 col-lg-6">
                <input name="company" className="p_form_control" type="text" placeholder={this.text[19]} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
                <input name="phone" className="p_form_control" type="tel" placeholder={this.text[20]} required />
            </div>
            <div className="col-12 col-lg-6">
                <input name="email" className="p_form_control" type="email" placeholder={this.text[21]} required />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
                <textarea name="message" className="p_form_control" placeholder={this.text[22]}></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-12 aszf checkbox">
                <input type="checkbox" id="aszf" name="aszf" value="accepted" required />
                <label htmlFor="aszf"> <a href={this.props.domain + '/uploads/PUSKAS_ARENA_adatkezelesi.tajekoztato.pdf'} target="_BLANK">{this.text[32]}</a> </label>
            </div>
          </div>
          <div className="row">
            <div className="col-12 btn">
              
                <button className="btn_red" name="contact">{this.text[23]}</button>
            </div>
          </div>
          {this.state.message_type != ''?
            <div className={ "message_" + this.state.message_type }>
              <div className="title">{this.state.message_title}</div>
              {this.state.message}
            </div>
            :<></>}
        </form>
      </>
    );
    
  }
}

export class InitContactForm {
  constructor() {
      var div: HTMLDivElement = document.getElementById('contact_form') as HTMLDivElement;
      if (div !== null) {
          var data:any = div.dataset;
          ReactDOM.render(<ContactForm domain={data.domain} language={data.language} texts={data.texts} />, div);
      }
  }
}