import * as React from "react";
import * as ReactDOM from "react-dom";
import Ajax from "../../Ajax";

interface IFormProps {
  domain: string,
  language: string,
}
interface MyStates {
  usernameField: string,
  usernamesg: string,
  clicked: boolean,
}
export default class Username extends React.Component<IFormProps, MyStates>{


  constructor(props:IFormProps) {
    super(props);

    this.state = { usernameField: 'invalid', usernamesg: '', clicked: false }
  }


  private check_username(e){
    if(e.target.value == ''){
      if( this.state.usernameField != 'invalid' ){
        this.setState({
          usernameField: 'invalid',
          usernamesg: 'Kötelező megadni',
          clicked: true
        })
      }
    }else{
      var ajax = new Ajax( this.props.domain + '/api/checkusername' );
      ajax.doPost({username: e.target.value}).then((response) => {
        this.setState({
          usernameField: response.status,
          usernamesg: response.msg,
          clicked: true
        })
      })
    }
  }

  public render() {
    return (
      <>
        <div className="col-12 col-md-6">
          <input type="text" className="p_form_control" name="username" placeholder="Nicknév"  onBlur={ (e) => this.check_username(e) } required />
          <div className={ (this.state.clicked?'':' hiddencallout') } key={"password_"+Math.random()}>
            <div className={this.state.usernameField == 'valid'?'callout callout-success':'callout callout-danger'}>
            <p>{this.state.usernamesg}</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}