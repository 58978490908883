import * as React from "react";
import * as ReactDOM from "react-dom";
import Ajax from "../Ajax";

interface IFormProps {
  domain: string,
  language: string,
  texts: string,
}
interface MyStates {
    message: string
    message_title: string
    message_type: string
}
export default class NewsletterForm extends React.Component<IFormProps, MyStates>{
  private text = '';
  private rts = {data:{}};

  constructor(props:IFormProps) {
    super(props);

    this.text = JSON.parse(this.props.texts);

    this.state = {message: '', message_title: '', message_type: ''}
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  private handleSubmit(event) {
    event.preventDefault();
    var ajax = new Ajax( this.props.domain + '/api/newsletterform' );
    ajax.doPost('nlform', true).then((response) => {
      this.setState({message_type: response.status, message: response.msg, message_title: response.title})
    })
  }

  public render() {
    return (
      <>
        <form action="/regisztracio" id="nlform" key={"contactform" + Math.random()} method="post">
          <input type="email" className="form-control" name="email" placeholder={this.text[10]} required />
          <button type="submit" className="btn_green">{this.text[11]}</button>
        </form>
      </>
    );
    
  }
}

export class InitNewsletterForm {
  constructor() {
    var div: HTMLDivElement = document.getElementById('nlf') as HTMLDivElement;
    if (div !== null) {
      var data:any = div.dataset;
      ReactDOM.render(<NewsletterForm domain={data.domain} language={data.language} texts={data.texts} />, div);
    }
  }
}