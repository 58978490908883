import { InitContactForm } from "./forms/ContactForm";
import { InitOfferRequestForm } from "./forms/OfferRequestForm";
import { InitNewsletterForm } from "./forms/NewsletterForm";
import { InitRegForm } from "./forms/RegForm";


class Main{
    constructor(){
        new InitContactForm();
        new InitOfferRequestForm();
        new InitNewsletterForm();
        new InitRegForm();
    }
}
new Main();